<template>
  <div style="height: 100%;overflow: hidden;">
    <van-loading size="24px" style="text-align:center;top:50px;" v-show="showPdf">正在加载</van-loading>
	  <div id="app"><div id="demo"></div></div>
  </div>
</template>
 
<script>
// import wx from 'weixin-js-sdk';
import Pdfh5 from 'pdfh5';
import axios from 'axios';
  export default {
    data () {
      return {
				pdfh5: null,
        agreementUrl:this.$route.query.url,
        showPdf:true,
        pdfName: this.$route.query.pdfName
       }
    },
    mounted(){
      window.parent.postMessage({
				isLoading: 'false'
			},'*')
    },
    created(){
      // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
      // var url = 'http://10.6.132.166:8263/oraflfile/content/group2/M00/00/D2/CgaEpmY_Jy2AbWyKAAG3CR91HVQ612.pdf?Authorization=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjaGVueXUiLCJhdWQiOiJjaGVueXUiLCJoeklkIjoiMTEwMCIsImV4cCI6ODM4MjA4MTEwNywiaWF0IjoxNzE1NDE0NDQxLCJjaGFubmVsSWQiOiIxMTAwIiwidXNlcm5hbWUiOiJjaGVueXUifQ.jz_2r4KOG2Ax0g3tbk56RaaHYOkNIGat8OCh11BDB4s';
      // var url = this.agreementUrl+'?Authorization='+localStorage.getItem("token");
      var url = this.agreementUrl
      axios.post('/wxapi/getpdf',{
        pdf_name: this.pdfName
      }, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        this.pdfh5 = new Pdfh5('#demo', {
          data: res.data
        });
        this.showPdf = false;
      });
　　},
    method: {

    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
